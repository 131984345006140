import { io } from "socket.io-client";
import Settings from "@/../settings.json";
class SocketioService {
  socket;
  constructor() {}

  setupSocketConnection() {
    const accessToken = localStorage.getItem("accessToken");
    this.socket = io(Settings.BASE_URL_SOCKET, {
      query: {
        accessToken,
      },
    });
    this.socket.emit("ADMIN_VOLUME_MONITOR");
  }
}

export default new SocketioService();
