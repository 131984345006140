<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
      <card>
        <div>
          <h5 slot="header" class="title">List User</h5>
          <div class="row">
            <div class="col-md-5 pr-md-1">
              <base-select :options="optionsArea" label="Sàn" v-model="searchInput.areaName" name="item-areaName">
      
              </base-select>
      
            </div>
            <div class="col-md-5 pr-md-1">
              <base-input label="nickName" v-model="searchInput.nickName" name="item-nickName">
              </base-input>
            </div> 
            <div class="mt-3">
              <base-button class="mt-2 ml-3" @click=getListUser>Search</base-button>
            </div>
          </div>
        </div>
      </card> 
      <card>
        <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>nickName</th>
                  <th>Email</th>
                  <th>Type Account</th>
                  <th>Ref Code</th>
                  <th>Sponsor ID</th>
                  <th>Level Vip</th>
                  <th>Balance</th>
                  <th>Live Balance</th>
                  <th>Edit</th>
  
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in paginatedData" :key="index" >
                  <td>{{ data.id }}</td>
                  <td>{{ data.nickName }}</td>
                  <td>{{ data.email }}</td>
                  <td v-if="data.marketing == false">Trader</td>
                  <td v-else>Markerting</td>
                  <td>{{ data.refCode }}</td>
                  <td>{{ data.uplineId }}</td>
                  <td>{{ data.levelVip }}</td>
                  <td class="green">${{ data.balance }}</td>
                  <td class="green">${{ data.balance }}</td>
                  <vs-button @click=getAllMember(data.nickName)>Edit</vs-button>
                </tr>
              </tbody>
            </table>
          </div>
          <vs-pagination v-model="page" :length="pageCount" @input="updateData"></vs-pagination>
      </card>
      <card>
        <div>
          <h5 slot="header" class="title">Edit Profile</h5>
        <div class="row">
         
          <div class="col-md-4 ">
            <base-input label="id" v-model="dataUser.id" name="item-Id">
    
            </base-input>
          </div>
          <div class="col-md-4 pl-md-1">
            <base-input label="Email" v-model="dataUser.email" name="item-Email">
            </base-input>
          </div>
          <div class="col-md-4 pl-md-1">
            <base-input label="nickName" v-model="dataUser.nickName" name="item-nickname">
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 pr-md-1">
            <base-input label="Surname" v-model="dataUser.firstName" name="item-last_name">
    
            </base-input>
          </div>
          <div class="col-md-6 pl-md-1">
            <base-input label="Lastname" v-model="dataUser.lastName" name="item-first_name">
            </base-input>
          </div>
          <div class="col-md-6 pl-md-1">
            <base-input :readonly="true" label="Refferal code" v-model="dataUser.refCode" name="item-refCode">
            </base-input>
    
          </div>
          <!-- <div class="col-md-6 pl-md-1">
            <base-input v-if="dataUser.marketing == false" label="UID upline" v-model="dataUser.uplineUid"
              name="item-uplineId">
            </base-input>
          </div> -->
          <div class="col-md-6 pl-md-1">
            <base-input aria-disabled="true" v-if="dataUser.marketing == false" label="uplineId ID"
              v-model="dataUser.UpNick" name="item-uplineId">
            </base-input>
          </div>
          <!-- <div class="col-md-6 pl-md-1">
            <base-input v-if="dataUser.marketing == false" label="ID upline" v-model="dataUser.uplineId" name="item-uplineId">
            </base-input>
          </div> -->
          
        </div>
    
        <div class="row">
          <div class="col-md-6">
            <vs-checkbox v-model="dataUser.vipUser" @change="handleChange">
               VIP Member
            </vs-checkbox>
          </div>
        </div>
        <div v-if="dataUser.vipUser">
          <div class="row">
            <div class="col-md-6">
              <base-select :options="optionsLevel" label="Level Vip" v-model="dataUser.levelVip" name="levelVip">
              </base-select>
    
            </div>
    
            <div class="col-md-4 pl-md-1">
              <base-input type="date" label="Day expire" v-model="dataUser.vipLevelExpTime"> </base-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <vs-checkbox label="Marketing" v-if="dataUser.uplineUid == 0 " v-model="dataUser.marketing" @change="handleChangeMKT">
            Marketed</vs-checkbox>
            <vs-checkbox v-else label="Marketing" v-model="dataUser.marketing" aria-placeholder="" aria-readonly="true" > Marketing</vs-checkbox>
          </div>
          <div class="col-md-2">
          <div v-if="dataUser.marketing" class=" px-md-1">
            <base-input type="number" label="Add" v-model="amountmkt" name="addmoney">
            </base-input>
            
          </div>
          <base-button v-if="dataUser.marketing" @click=AddMoneyMKT()>ADD</base-button>
        </div>
          
        </div>
        <div class="row">
            <div class="col-md-2">
              <vs-checkbox v-model="dataUser.activate" @change="handleChangeActivate">
                Active
              </vs-checkbox>
           
            
                <vs-checkbox v-model="dataUser.lockStatus" @change="handleChangeLock">
                    Lock
                  </vs-checkbox>
           
            
                <vs-checkbox v-model="dataUser.blacklist" @change="handleChangeBlack">
                    Blacklist
                  </vs-checkbox>
      
            
                <vs-checkbox v-model="dataUser.verify" @change="handleChangeVerify">
                    Verify (KYC)
                  </vs-checkbox>
         
            
                <vs-checkbox v-model="dataUser.activate2FA" @change="handleChangeMKT">
                    2FA
                  </vs-checkbox>
            </div>
        </div>
    
        
        </div>
        <base-button @click=UpdateUser()>Save</base-button>
    
      </card>
      <card>
        <div class="row">
            <div v-if="dataUser.order && dataUser.order.length >= 2" class="col-md-2 px-md-1">
                <base-input :readonly="true" label="TK USDT" v-model="dataUser.balance" name="Balance">
                </base-input>
              </div>
              
              <div v-if="dataUser.order && dataUser.order.length >= 2" class="col-md-2 px-md-1" >
                <base-input label="TK LIVE" v-model="dataUser.order[1].balance" name="BalanceLive"></base-input>
              </div>

              <div class="col-md-2 pr-md-1">
                <base-select :options="optionsAcc" label="Type wallet" v-model="dataAdd.type" name="item-areaName">
                </base-select>
              </div>
              <div class="col-md-2 px-md-1">
                <base-input label="Amount" v-model="dataAdd.amount" name="Amount"></base-input>
              </div>
              <div class="col-md-2 px-md-1">
                <base-input label="Memo" v-model="dataAdd.memo" name="Memo"></base-input>
              </div>
              <div class="col-md-2 pr-md-1 mt-3">
                <base-button class="bg-green" @click=addMoneyAdmin()>Add</base-button>
            </div>
            <div class="col-md-2 pr-md-1 mt-3">
                <base-button class="bg-red" @click=removeMoneyAdmin()>Remove</base-button>
            </div>
        </div>
        
        
      </card>
    </div>
    
  </template>
  <script>
  import AuthenticationService from '../services/AuthenticationService';
  import { VueSelect, VueSelectItem } from 'vue-select'
  import BaseDropdown from '../../components/BaseDropdown.vue';
  import BaseSelect from '../../components/BaseSelect.vue';
  
  export default {
    props: {
      data: {
        type: Object,
        default: () => { }
  
      }
    },
    components: {
      'vs-select': VueSelect,
      'vs-select-item': VueSelectItem,
      'base-select': BaseSelect,
  
  
    },
    data() {
      return {
        dataAdd: {
            amount: 0,
            type: ""
        },
        datadeposit: [],
        itemsPerPage: 10,
        page: 1,
        dataUser: {
          marketing: false,
          vipUser: false,
          levelVip: 1,
          active: false,
          lockStatus: false,
          activate2FA: false,
          secret2FA: "",
          vipLevelExpTime: "",
          order: [],
          amount: 0,
          UpNick: "",
          verify: false,
          blacklist: false,
          phoneNumber: ""
        },
        searchInput: {
          areaName: 'DBZ',
          nickName: ''
        },
        amountmkt: 0,
        email: '',
        userData: null,
        updatedFields: {},
        hiddenOption: null,
        optionsArea: [
          { value: "DBZ", label: "DBZ" },
        ],
        optionsAcc: [
          { value: "USDT", label: "USDT" },
          { value: "LIVE",label: "LIVE"}
        ],
        optionsLevel: [
          { value: "0", label: "Level 0" },
          { value: "1", label: "Level 1" },
          { value: "2", label: "Level 2" },
          { value: "3", label: "Level 3" },
          { value: "4", label: "Level 4" },
          { value: "5", label: "Level 5" },
          { value: "6", label: "Level 6" },
          { value: "7", label: "Level 7" }
        ],
      }
    },
    methods: {
  
      handleChange() {
        this.dataUser.vipUser = this.dataUser.vipUser ? true : false;
      },
      handleChangeMKT() {
        if (this.dataUser.marketing === false) {
          this.dataUser.marketing = true; // Enable the checkbox
        } else {
          this.dataUser.marketing = true; // Lock the checkbox in the checked state
        }
      },
      handleChangeActivate() {
        this.dataUser.activate = this.dataUser.activate ? true : false;
      },
      handleChangeLock() {
        this.dataUser.lockStatus = this.dataUser.lockStatus ? true : false;
      },
      handleChangeBlack() {
        this.dataUser.blacklist = this.dataUser.blacklist ? true : false;
      },
        handleChangeVerify() {
           if (this.dataUser.verify) {
          this.dataUser.verify = false;
        } else {
          this.dataUser.verify = 2;
        }
  
      },
      handleChangeActivate2FA() {
        this.dataUser.activate2FA = this.dataUser.activate2FA ? true : false;
      },
      formatPrice(value, minimum) {
        var formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: minimum
        });
        return formatter.format(value);
      },
  
      getListUser() {
        const obj = {
          nickName: this.searchInput.nickName,
          areaName: this.searchInput.areaName,
        }
        console.log(obj);
        AuthenticationService.getListUser(obj)
          .then((res) => {
            if (res.data.success === true) {
              this.datadeposit = res.data.data;
              return this.$vs.notification({
                text: ' Thành công ',
                color: '#4B0082',
              });
            } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
              this.$router.push('/goldentplus').catch(() => { })
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("INFO");
              return this.$vs.notification({
                text: 'Phiên đăng nhập đã hết hạn! ',
                color: '#4B0082',
              });
            } else {
              return this.$vs.notification({
                text: 'Biệt danh không tồn tại ! ',
                color: '#4B0082',
              });
            }
          })
      },
      getAllMember(data) {
      const obj = {
        nickName: data.toLowerCase(),
        areaName: "DBZ"
      }
      console.log(obj);
      AuthenticationService.getAllMember(obj)
        .then((res) => {
          if (res.data.success === true) {
            this.dataUser = res.data;
            return this.$vs.notification({
              text: ' Thành công ',
              color: '#4B0082',
            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/goldentplus').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } else {
            return this.$vs.notification({
              text: 'Biệt danh không tồn tại ! ',
              color: '#4B0082',
            });
          }
        })
    },
    
      UpdateUser() {
        const obj = {
          id: this.dataUser.id,
          areaName: "DBZ",
          nickName: this.dataUser.nickName.toLowerCase(),
          email: this.dataUser.email.toLowerCase(),
          firstName: this.dataUser.firstName,
          lastName: this.dataUser.lastName,
          refCode: this.dataUser.refCode,
          uplineUid: this.dataUser.uplineUid,
          uplineId: this.dataUser.uplineId,
          vipUser: this.dataUser.vipUser,
          levelVip: this.dataUser.levelVip,
          balance: this.dataUser.balance,
          marketing: this.dataUser.marketing,
          commission: this.dataUser.commission,
          order: this.dataUser.order,
          vipLevelExpTime: this.dataUser.vipLevelExpTime,
          levelVip: this.dataUser.levelVip,
          activate: this.dataUser.activate,
          activate2FA: this.dataUser.activate2FA,
          secret2FA: this.dataUser.secret2FA,
          verify: this.dataUser.verify,
          
          lockStatus: this.dataUser.lockStatus,
          blacklist: this.dataUser.blacklist,
          UpNick: this.dataUser.UpNick,
        }
  
        AuthenticationService.updateUserInfo(obj)
          .then((resp) => {
            if (resp.data.success) {
                this.getListUser();
              return this.$vs.notification({
                text: 'Đã cập nhập thông tin thành công ',
                color: '#4B0082',
  
              });
            } else if (resp.data.success === false && resp.data.errorType == "invalidAccessToken") {
              this.$router.push('/goldentplus').catch(() => { })
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("INFO");
              return this.$vs.notification({
                text: 'Phiên đăng nhập đã hết hạn! ',
                color: '#4B0082',
              });
            } else if (resp.data.success === false && resp.data.errorType == "nickNameAlreadyExists") {
              return this.$vs.notification({
                text: 'Biệt danh đã tồn tại! ',
                color: '#4B0082',
              });
            } else {
              return this.$vs.notification({
                text: "Không thể cập nhật thông tin !",
                color: '#4B0082',
  
              });
            }
          })
      },
      AddMoneyMKT() {
        let amount = Number(this.amountmkt);
        const obj = {
          amount: amount,
          id: this.dataUser.id,
        }
        AuthenticationService.sendBalanceMarketing(obj)
          .then((resp) => {
            if (resp.data.success) {
              this.dataUser.uplineUid = null
              this.dataUser.uplineId = null
              this.dataUser.balance = Number(this.amountmkt) + Number(this.dataUser.balance);
  
              return this.$vs.notification({
                text: 'Đã bơm tiền thành công!',
                color: 'success',
                iconPack: 'feather',
                icon: 'icon-check'
              });
  
            } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
              this.$router.push('/goldentplus').catch(() => { })
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("INFO");
              return this.$vs.notification({
                text: 'Phiên đăng nhập đã hết hạn! ',
                color: '#4B0082',
              });
            } else {
              return this.$vs.notification({
                text: "Không thể cập nhật thông tin !",
                color: 'danger',
                iconPack: 'feather',
                icon: 'icon-alert-circle'
              });
            }
          })
  
      },
      addMoneyAdmin() {
        let amount = Number(this.dataAdd.amount);
        const obj = {
          amount: amount,
          id: this.dataUser.id,
          typeAcc: this.dataAdd.type,
          memo: this.dataAdd.memo
        }
        AuthenticationService.addMoneyAdmin(obj)
          .then((resp) => {
            if (resp.data.success == true) {
              this.getListUser();
              this.getAllMember(this.dataUser.nickName);
              return this.$vs.notification({
                text: 'Đã bơm tiền thành công!',
                color: 'success',
                iconPack: 'feather',
                icon: 'icon-check'
              });
  
            } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
              this.$router.push('/goldentplus').catch(() => { })
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("INFO");
              return this.$vs.notification({
                text: 'Phiên đăng nhập đã hết hạn! ',
                color: '#4B0082',
              });
            } else {
              return this.$vs.notification({
                text: "Không thể cập nhật thông tin !",
                color: 'danger',
                iconPack: 'feather',
                icon: 'icon-alert-circle'
              });
            }
          })
      },
      removeMoneyAdmin() {
        let amount = Number(this.dataAdd.amount);
        const obj = {
          amount: amount,
          id: this.dataUser.id,
          typeAcc: this.dataAdd.type,
          memo: this.dataAdd.memo
        }
        AuthenticationService.removeMoneyAdmin(obj)
          .then((resp) => {
            if (resp.data.success) {
              this.getListUser();
              this.getAllMember(this.dataUser.nickName);
              return this.$vs.notification({
                text: 'Đã trừ tiền thành công!',
                color: 'success',
                iconPack: 'feather',
                icon: 'icon-check'
              });
  
            } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
              this.$router.push('/goldentplus').catch(() => { })
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("INFO");
              return this.$vs.notification({
                text: 'Phiên đăng nhập đã hết hạn! ',
                color: '#4B0082',
              });
            } else {
              return this.$vs.notification({
                text: "Không thể cập nhật thông tin !",
                color: 'danger',
                iconPack: 'feather',
                icon: 'icon-alert-circle'
              });
            }
          })
      }
    },
    computed: {
      levelVipValue() {
        return this.dataUser.vipUser ? this.dataUser.levelVip : '';
      },
      marketingValue() {
        return this.dataUser.vipUser ? this.dataUser.marketing : '';
      },
      expirationDateValue() {
        return this.dataUser.vipUser ? this.dataUser.vipLevelExpTime : '';
      },
      verifyValue() {
        return this.dataUser.verify ? this.dataUser.verify : '';
      },
      blackValue() {
        return this.dataUser.blacklist ? this.dataUser.blacklist : '';
      },
      pageCount() {
        return Math.ceil(this.datadeposit.length / this.itemsPerPage);
      },
      paginatedData() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.datadeposit.slice(start, end);
      },
    }
  }
  </script>
  <style>
.bg-red{
    color: rgb(238, 26, 26);
}
.bg-green{
    color: green
}
</style>
  