<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>

    <h5 slot="header" class="title">Edit Bet ( Bẻ Cầu )</h5>
    <div>
      <base-button slot="footer" type="primary" fill @click=getBreakBetsConfig>ACTIVE</base-button>
    </div>
    <div class="row">
      <div class="col-md-8">
        <vs-checkbox v-model="dataUser.differenceBreakBets" @change="handleChange2" label="Bẻ cầu chênh lệnh">Bẻ cầu chênh
          lệch</vs-checkbox>
      </div>
      <div class="col-md-8">
        <base-input label="Số tiền chênh lệnh" v-model="dataUser.differenceOver" name="item-differenceOver">
        </base-input>
      </div>
      <div class="col-md-8">
        <base-input label="Thời gian bẻ lệnh" v-model="dataUser.breakBetsStartSecond" name="item-breakBetsStartSecond">
        </base-input>
      </div>
      <div class="col-md-8">

        <vs-checkbox v-model="dataUser.priceFundOnOff" @change="handleChange" label="Bẻ cầu theo quỹ">Bẻ
          cầu theo quỹ</vs-checkbox>
      </div>
      <div class="col-md-8">
        <base-input label="Số tiền của quỹ" v-model="dataUser.priceFundProfits" name="item-priceFundProfits">
        </base-input>
      </div>
      <div class="col-md-8">
        <base-input label="Giới hận âm của quỹ" v-model="dataUser.priceStopLoss" name="item-priceStopLoss">
        </base-input>
      </div>
    </div>

    <base-button slot="footer" type="primary" fill @click=updateBreakBetsConfig()>Save</base-button>

  </card>
</template>
<script>
import AuthenticationService from '../services/AuthenticationService';
import { VueSelect, VueSelectItem } from 'vue-select'
import BaseDropdown from '../../components/BaseDropdown.vue';

export default {
  props: {
    data: {
      type: Object,
      default: () => { }

    }
  },
  components: {
    'vs-select': VueSelect,
    'vs-select-item': VueSelectItem


  },
  data() {
    return {
      dataUser: {
        differenceBreakBets: false,
        priceFundOnOff: false,
      },

      differenceOver: '',
      breakBetsStartSecond: '',

      priceFundProfits: '',
      priceStopLoss: '',


      updatedFields: {},

    }
  },
  methods: {

    handleChange() {
      if (this.dataUser.priceFundOnOff === "false") {
        this.dataUser.priceFundOnOff = false;
      } else {
        this.dataUser.priceFundOnOff = true;
      }
    },

    handleChange2() {
      if (this.dataUser.differenceBreakBets === "false") {
        this.dataUser.differenceBreakBets = false;
      } else {
        this.dataUser.differenceBreakBets = true;
      }
    },


    getBreakBetsConfig() {
      AuthenticationService.getBreakBetsConfig()
        .then((res) => {
          if (res.data.success) {
            this.dataUser = res.data.data;
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: 'success',
              iconPack: 'feather',

            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/goldentplus').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } if (res.data.success == false && res.data.errorType == '') {
            return this.$vs.notification({
              text: "Không thể tìm kiếm thông tin !",
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });
          }
        })
    },

    updateBreakBetsConfig() {
      const obj = {
        differenceBreakBets: this.dataUser.differenceBreakBets,
        differenceOver: parseFloat(this.dataUser.differenceOver),
        breakBetsStartSecond: parseFloat(this.dataUser.breakBetsStartSecond),
        priceFundOnOff: this.dataUser.priceFundOnOff,
        priceFundProfits: parseFloat(this.dataUser.priceFundProfits),
        priceStopLoss: parseFloat(this.dataUser.priceStopLoss)
      }

      console.log(obj);
      AuthenticationService.updateBreakBetsConfig(obj)
        .then((resp) => {
          if (resp.data.success) {
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: 'success',
              iconPack: 'feather',

            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/goldentplus').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } else {
            return this.$vs.notification({
              text: "Không thể cập nhật thông tin !",
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });
          }
        })
    }
  },
  computed: {
    differenceBreakBetsValue() {
      return this.dataUser.differenceBreakBets ? this.dataUser.differenceBreakBets : '';
    },
    priceFundOnOffValue() {
      return this.dataUser.priceFundOnOff ? this.dataUser.priceFundOnOff : '';
    },
  },
}
</script>
<style></style>
