<template>
  <div class="row">
    <div class="col-12">
      <card title="Trade History ">
        <div class="col-md-5 pr-md-1">
          <base-select :options="optionsArea" label="areaName" v-model="searchInput.areaName" name="item-areaName">

          </base-select>

        </div>
        <div class="col-md-5 pr-md-1">
          <base-input label="nickName" v-model="searchInput.nickName" name="item-nickName">
          </base-input>
        </div>
        <base-button @click=getListTrade()>Get Data</base-button>
        <base-button @click=getListTradeNick()>Search Data User</base-button>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Area Name</th>
                <th>Nick Name</th>
                <!-- <th>Account Type</th> -->
                <th>Amount Bet</th>
                <th>Amount Win</th>
                <th>Amount Lose</th>

                <th>Bet Type</th>
                <th>Break Bet</th>
                <th>Result</th>
                <th>Bet Result</th>
                <th>Balance</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in paginatedData" :key="index">
                <td>{{ data.areaName }}</td>
                <td>{{ data.nickName }}</td>
                <!-- <td>{{ data.accountType }}</td> -->
                <td>${{ data.amountBet.toFixed(2) }}</td>
                <td>${{ data.amountWin.toFixed(2) }}</td>
                <td>${{ data.amountLose.toFixed(2) }}</td>

                <td v-if="data.betType == 'BUY'" class="green">BUY</td>
                <td v-if="data.betType == 'SELL'" class="red">SELL</td>

                <td v-if="data.breakType == 'BUY'" class="green">BUY</td>
                <td v-if="data.breakType == 'SELL'" class="red">SELL</td>
                <td v-if="data.breakType == 'NONE'" class="white">NONE</td>

                <td v-if="data.open < data.close" class="green">BUY</td>
                <td v-if="data.open > data.close" class="red">SELL</td>
                <td v-if="data.open == data.close" class="white">DRAW</td>

                <td v-if="data.amountWin" class="green" >WIN</td>
                <td v-if="data.amountLose" class="red">LOSE</td>
                <td v-if="data.amountWin == '0' & data.amountLose == '0'">DRAW</td>

                <td>{{ data.note }}</td>
                <td>{{ new Date(data.createdAt).toLocaleString() }}</td>
                <td v-if="data.status=='1'">Done</td>
                <td v-if="data.status=='0'">Pending</td>

              </tr>
            </tbody>
          </table>
        </div>
        <vs-pagination v-model="page" :length="pageCount" @input="updateData"></vs-pagination>
      </card>
    </div>
    <div class="col-12">
      <card title="Check Profit ">
        <div class="col-md-5 pr-md-1">
          <base-input label="nickName" v-model="adminInput.nickName" name="item-nickName">
          </base-input>
        </div>
        
        <div class="col-md-5 pr-md-1">
          <base-input type="datetime-local" label="From Time" v-model="adminInput.fromTime" name="item-fromTime" :value="defaultFromDate">
          </base-input>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input type="datetime-local" label="To Time" v-model="adminInput.toTime" name="item-toTime" :value="defaultFromDate">
          </base-input>
        </div>
        <base-button @click=getProfit()>Get Data</base-button>
        <div class="col-md-5 pr-md-1">
          <base-input label="Total Bet" v-model="dataProfit.bet" name="item-nickName">
          </base-input>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input label="Total Win" v-model="dataProfit.win" name="item-nickName">
          </base-input>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input label="Total Lose" v-model="dataProfit.lose" name="item-nickName">
          </base-input>
        </div>
        <div class="col-md-5 pr-md-1">
          <base-input label="Total Profit" v-model="dataProfit.profit" name="item-nickName">
          </base-input>
        </div>
      
    
  
      </card>
    </div>



  </div>
</template>
<script>
import { BaseTable } from "@/components";
import AuthenticationService from './services/AuthenticationService';
const tableColumns = ["AreaName", "NickName", "From", "Amount", "Time", "Status"];
import BaseSelect from "../components/BaseSelect";



export default {
  components: {
    BaseTable,
    'base-select': BaseSelect,

  },
  props: {

  },
  data() {
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const monthYesterday = (yesterday.getMonth() + 1).toString().padStart(2, '0');
    const dateYesterday = yesterday.getDate().toString().padStart(2, '0');
    const fromTime = `${yesterday.getFullYear()}-${monthYesterday}-${dateYesterday}T07:00`;

    const monthToday = (now.getMonth() + 1).toString().padStart(2, '0');
    const dateToday = now.getDate().toString().padStart(2, '0');
    const toTime = `${now.getFullYear()}-${monthToday}-${dateToday}T07:00`;
    return {
      datatrade: [],
      itemsPerPage: 10,
      page: 1,
      optionsArea: [
        { value: "DBZ", label: "DBIZ" },
      ],
      searchInput: {
        areaName: "ALL",
        nickName: "",
      },
      areaName: '',
      dataInput: [],
      result: '',
      adminInput: {
        name: "",
        fromTime: fromTime,
        toTime: toTime,
      },
      dataProfit: []
    };
  },
  computed: {
    pageCount() {

      return Math.ceil(this.datatrade.length / this.itemsPerPage);

    },
    paginatedData() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.datatrade.slice(start, end);
    },
    defaultFromDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const day = now.getDate();
      const time = "07:00 AM";
      return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}T${time}`;
    },
  },
  methods: {
    getListTrade() {
      let obj = {
        areaName: this.searchInput.areaName
      };
      AuthenticationService.getListTrade(obj)

        .then((resp) => {
          if (resp.data.success) {
            this.datatrade = resp.data.data;
            console.log(this.datatrade);
            // if (datatrade.open > datatrade.close) {
            //   this.result = "BUY"
            // } if (this.datatrade.open < this.datatrade.close) {
            //   this.result = "SELL"
            // } if (this.datatrade.open = this.datatrade.close) {
            //   this.result = "DRAW"
            // }
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: '#4B0082',
            });
          } if (resp.data.success === false && resp.data.errorType == "invalidAccessToken") {
            this.$router.push('/goldentplus').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          }
        })
    },
    getListTradeNick() {
      let obj = {
        areaName: this.searchInput.areaName,
        nickName: this.searchInput.nickName
      }
      AuthenticationService.getListTradeNick(obj)
        .then((resp) => {
          if (resp.data.success) {
            this.datatrade = resp.data.data;
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: '#4B0082',
            });
          } if (resp.data.success === false && resp.data.errorType == "invalidAccessToken") {
            this.$router.push('/goldentplus').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          }
        })
    },
    updateData(page) {
      this.page = page;
    },
    getProfit() {
  let obj = {
    nickName: this.adminInput.nickName,
    fromTime: this.adminInput.fromTime,
    toTime: this.adminInput.toTime,
  };

  AuthenticationService.checkProfit(obj)
    .then((resp) => {
      if (resp.data.success) {
        this.dataProfit = resp.data.data;
        return this.$vs.notification({
          text: 'Đã cập nhập thông tin thành công ',
          color: '#4B0082',
        });
      } else if (resp.data.success === false && resp.data.errorType == "invalidAccessToken") {
        this.$router.push('/goldentplus').catch(() => { })
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("INFO");
        return this.$vs.notification({
          text: 'Phiên đăng nhập đã hết hạn! ',
          color: '#4B0082',
        });
      } else if (resp.data.errorType == "Usermarketing") {
        return this.$vs.notification({
          text: 'Biệt danh không hợp lệ! ',
          color: '#4B0082',
        });
      }
    })
    .catch((error) => {
      console.error(error);
      this.$vs.notification({
        text: 'Biệt danh không hợp lệ!',
        color: '#4B0082',
      });
    });
}


  }
};
</script>
<style>
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  color: #fff ;
}

.table th {
  font-weight: bold;
  background-color: #00172e;
  border-bottom: 2px solid #dee2e6;
  color: #fff !important;
}

.table tbody tr:nth-child(even) {
  background-color: #070025;
}

.green {
  color: rgb(0, 255, 0) !important;
}

.red {
  color: red !important;
}
.white {
  color: white !important;
}
</style>
