<template>
  <div class="row">
    <div class="col-12">
      <card title="Add Money ">
        <base-button @click=getListAdd()>Get Data</base-button>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Area Name</th>
                <th>Nick Name</th>
                <th>From</th>
                <th>Amount</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in paginatedData" :key="index">
                <td>{{ data.areaName }}</td>
                <td>{{ data.nickName }}</td>
                <td>{{ data.from }}</td>
                <td>${{ data.amount }}</td>
                <td>{{ new Date(data.createdAt).toLocaleString() }}</td>
                <td>{{ data.status }}</td>

              </tr>
            </tbody>
          </table>
        </div>
        <vs-pagination v-model="page" :length="pageCount" @input="updateData"></vs-pagination>
      </card>
    </div>



  </div>
</template>
<script>
import { BaseTable } from "@/components";
import AuthenticationService from './services/AuthenticationService';
const tableColumns = ["AreaName", "NickName", "From", "Amount", "Time", "Status"];


export default {
  components: {
    BaseTable
  },
  props: {

  },
  data() {
    return {
      datamoney: [],
      itemsPerPage: 10,
      page: 1
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.datamoney.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.datamoney.slice(start, end);
    },
  },
  methods: {
    getListAdd() {
      AuthenticationService.getListAdd()
        .then((resp) => {
          if (resp.data.success) {
            this.datamoney = resp.data.data;
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: '#4B0082',
            });
          } if (resp.data.success === false && resp.data.errorType == "invalidAccessToken") {
            this.$router.push('/goldentplus').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          }
        })
    },
    updateData(page) {
      this.page = page;
    }
  }
};
</script>
<style>
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table th {
  font-weight: bold;
  background-color: #00172e;
  border-bottom: 2px solid #dee2e6;
}

.table tbody tr:nth-child(even) {
  background-color: #070025;
}
</style>
